<template>
  <div class="search">
    <h1> Search </h1>
  </div>
</template>

<script>

export default {
  name: 'Search'
}
</script>
<style scoped>
  .search{
    position: relative;
    min-height: 100vh;
    width: 100%;
  }
</style>
